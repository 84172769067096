<template>
  <main platform>
    <p-section class="hero">
      <div class="holder" v-prx:sticky>
        <div class="scene-1">
          <div class="bg">
            <video src="/img/platform/hero.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
            <video src="/img/platform/hero.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
          </div>
          <h2>
            <img v-prx:progress="{ r:[0,2], s:[1,5], o:[1,0] }" src="/img/platform/fit.svg" alt="fit">
          </h2>
          <p>
            <span v-prx:progress="{ r:[2,3,5,6], o:[0,1,1,0], ty:[30,0,0,-30] }">공간의 <br class="hidden-tl-up">무한한 가능성</span>
          </p>
          <p>
            <span v-prx:progress="{ r:[6,7,9,10], o:[0,1,1,0], ty:[30,0,0,-30] }">사용자 맞춤형 <br class="hidden-dl-up">공간 플랫폼</span>
          </p>
          <img src="/img/platform/award.png" alt="" class="award">
          <img src="/img/platform/award2.png" alt="" class="award award-2">
          <div class="cover" v-prx:progress="{ r:[8,12], o:[0,1] }"></div>
        </div>
      </div>
    </p-section>
    <p-section class="hero">
      <div class="holder" v-prx:sticky>
        <div class="scene-2">
          <p>
            <span v-prx:intro="{ r:[0,12], ty:[-100,0], o:[0,1], unit:'%' }">
              <span v-prx:progress="{ r:[4,6], ty:[0,-100], o:[1,0], unit:'%' }">
                팬데믹 이후 사회 전반에 걸쳐 일어난 <br>
                변화는 미래형 공간에 대한 니즈와 <br>
                건설 혁신의 필요성을 가속화시켰습니다. <br>
                <br>
                지속될 변화에 효과적으로 대응하기 위해<br>
                공간환경은 다각화 되어야 합니다.<br>
                공간의 유연성이 필요한 시점입니다.
              </span>
            </span>
          </p>
          <p v-prx:progress="{ r:[6,9], o:[0,1] }">
            <span v-prx:progress="{ r:[6,9], ty:[200,0], unit:'%' }">
              <span v-prx:outro="{ r:[0,12], ty:[0,300], unit:'%' }">FIT을 <br class="hidden-tp-up">소개합니다.</span>
            </span>
          </p>
        </div>
      </div>
    </p-section>
    <p-section class="seq frame">
      <div class="holder">
        <div>
          <p>공간의 기반</p>
          <h3>FIT 프레임</h3>
          <router-link to="/frame" class="hidden-tp-down">바로가기</router-link>
        </div>
        <div>
          <video src="/img/platform/gate-frame.mp4" autoplay muted playsinline loop></video>
          <router-link to="/frame" class="hidden-tl-up">바로가기</router-link>
        </div>
      </div>
    </p-section>
    <p-section class="seq cartridge" :sq="180">
      <div class="holder">
        <div>
          <p>유연한 확장</p>
          <h3>FIT 카트리지</h3>
          <router-link to="/cartridge/skin" class="hidden-tp-down">바로가기</router-link>
        </div>
        <div>
          <video src="/img/platform/gate-cartridge.mp4" autoplay muted playsinline loop></video>
          <router-link to="/cartridge/skin" class="hidden-tl-up">바로가기</router-link>
        </div>
      </div>
    </p-section>
    <p-section class="seq os" :sq="95">
      <div class="holder">
        <div>
          <p>간편한 제어</p>
          <h3>FIT OS</h3>
          <router-link to="/os" class="hidden-tp-down">바로가기</router-link>
        </div>
        <div>
          <video src="/img/platform/gate-os.mp4" autoplay muted playsinline loop></video>
          <router-link to="/os" class="hidden-tl-up">바로가기</router-link>
        </div>
      </div>
    </p-section>
    <p-section class="map">
      <div class="holder" v-prx:sticky>
        <div class="img" v-prx:progress="{ r:[0,12], pan:[0,100] }">
          <img v-prx:intro="{ r:[4,10], o:[0,1], ty:[50,0] }" src="/img/platform/map.png" alt="" class="hidden-tl-up">
          <img v-prx:intro="{ r:[9,12], o:[0,1], ty:[50,0] }" src="/img/platform/map-txt.png" alt="" class="hidden-tl-up">
          <video src="/img/platform/map.mp4"  class="hidden-tp-down" autoplay muted playsinline></video>
        </div>
      </div>
    </p-section>
    <p-section class="reason">
      <div class="holder" v-prx:sticky>
          <div class="bg">
<!--            <video src="/img/platform/reason.l.webm" muted playsinline class="once hidden-ml-down"></video>-->
            <img src="/img/platform/reason.l.gif" class="hidden-tp-down">
            <video src="/img/platform/reason.s.mp4" muted playsinline class="once hidden-tl-up"></video>
          </div>
          <p v-prx:progress="{ r:[0,12], o:[0,1] }">FIT을 <br class="hidden-tl-up">선택하는 이유</p>
      </div>
    </p-section>
    <p-section class="feature">
      <div class="holder" v-prx:sticky>
        <ul v-prx:progress="{ r:[1,11], pan:[0,100] }">
          <li>
            <div class="inner-wrap">
              <div class="txt">
                <p>OPTIMIZED</p>
                <p>공간 <br>성능 향상</p>
                <p>가용 공간 내부에 위치해 있던 건물 인프라는 카트리지에 담겨 외부로 이동합니다. 건물의 실사용 면적은 약 20% 증가하고 인프라의 유지보수와 변경이 용이해집니다.</p>
                <div class="bottom">
                  <p>Increase Available Area</p>
                  <p>+20%</p>
                </div>
              </div>
              <video src="/img/platform/feature-1.mp4" autoplay muted playsinline loop></video>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <div class="txt">
                <p>CUSTOMIZED</p>
                <p>사용자 <br>맞춤형 공간</p>
                <p>FIT만의 다양한 카트리지를 쓸모에 맞게 교체하고 추가할 수 있습니다. 사용자의 니즈에 맞춰 공간의 쓰임을 간편하게 변경할 수 있습니다.</p>
                <div class="bottom">
                  <p>Optimal Space Comfort</p>
                  <p>
                    <img src="/img/platform/customized.l.png" class="hidden-tp-down"/>
                    <img src="/img/platform/customized.s.png" class="hidden-tl-up"/>
                  </p>
                </div>
              </div>
              <video src="/img/platform/feature-2.mp4" autoplay muted playsinline loop></video>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <div class="txt">
                <p>EFFICIENT</p>
                <p>차별화된 <br>건축 프로세스</p>
                <p>차별화된 건축 프로세스를 통해 설계 기간을 약 20% 단축하고, 가변성 높은 시스템을 적용하여 변경에도 효과적으로 대응합니다.</p>
                <div class="bottom">
                  <p>Shortening Schedule</p>
                  <p>-20%</p>
                </div>
              </div>
              <video src="/img/platform/feature-3.mp4" autoplay muted playsinline loop></video>
            </div>
          </li>
        </ul>
      </div>
    </p-section>
    <p-section class="eco" :sq="221">
      <div class="holder" v-prx:sticky>
        <h3>친환경 가치</h3>
        <div class="video hidden-ds-up">
          <video src="/img/platform/eco.l.mp4" muted playsinline class="once hidden-tp-down"></video>
          <video src="/img/platform/eco.m.mp4" muted playsinline class="once hidden-ml-down hidden-tl-up"></video>
          <video src="/img/platform/eco.s.mp4" muted playsinline class="once hidden-tp-up"></video>
        </div>
        <div class="img hidden-tl-down">
          <img :class="`sq-${i}`" :src="`/img/platform/eco/${String(i-1).padStart(3, '0')}.png`" v-for="i in 221" :key="i">
        </div>
        <div class="smart">
          <p>Smart X Green Plus</p>
          <ul>
            <li><p>Frame</p><p>-11.9%</p></li>
            <li><p>Skin Cartridge</p><p>-2.7%</p></li>
            <li><p>Light</p><p>-1.0%</p></li>
            <li><p>Air Handling Cartridge</p><p>-10.8%</p></li>
            <li><p>H&C Ceilings</p><p>-3.0%</p></li>
            <li><p>PV</p><p>-0.7%</p></li>
          </ul>
        </div>
        <p class="desc">
          FIT 시스템을 더할수록 ESG 가치도 함께 누적됩니다. <br class="hidden-tp-down">
          기존 건물 대비 연간 에너지 사용량은 30% 이상 감소합니다.
        </p>
      </div>
    </p-section>
  </main>
</template>
<script>

export default {
  name: 'CartridgeSkin',
  components: { },
  data() {
    return {
      skinNum: 0,
      colorNum: 0,
      sliderKey: 0,
      customColor: [
          ['White','Gold','havana Brown','beetle','deep blue','deep black','grey','pumkin','glowing red','sandy beige','spring green','midnight blue'],
          ['White','grey'],
          ['silver'],
          ['silver'],
      ],
      io: null,
    };
  },
  metaInfo() {
    return {
      title: 'FIT Future Space Platform',
      meta: [
        { vmid: 'title', content: 'FIT Future Space Platform', },
        { vmid: 'description', content: 'FIT Platform은 점점 다양해지는 사용자들의 니즈를 충족하기 위해 사용자 중심 맞춤형 공간 인프라를 마련하였습니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
  computed: {
    colors() {
      return this.customColor[this.skinNum];
    },
  },
  methods: {
  },
  mounted() {
    this.io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // entry의 target으로 DOM에 접근합니다.
        const $target = entry.target;

        // 화면에 노출 상태에 따라 해당 엘리먼트의 class를 컨트롤 합니다.
        if (entry.isIntersecting) {
          $target.play();
        } else {
          $target.pause();
          if ($target.currentTime > $target.duration - 3) $target.currentTime = 0;
        }
      });
    });

    const videos = this.$el.querySelectorAll('.once');
    videos.forEach(item => this.io.observe(item));

  },
  beforeDestroy() {
    this.io.disconnect();
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[platform] {
  .holder { .rel; .h(100vh); .-box; .crop; }
  .hero { .rel; .h(400vh);
    .holder { .pt(60); }
    h2, p { .abs; .lt(50%,50%); .t-xyc; span { .block; }}
    .scene-1 {
      .bg { .abs; .lt; .bgc(#000); .f;
        video { .block; .f; object-fit: cover; }
      }
      h2 img { .block; .w(314); }
      h2 ~ p { .wf; .tc; .fs(56,64); .bold; color: #1e1e1e; }
      .cover { .abs; .lt; .f; .bgc(#000); }
    }
    .scene-2 { .abs; .lt; .f; .bgc(#000);
      p { .fs(20,32); .bold; color: #fff; .wf; .tc; }
      p + p { .fs(56,64); }
    }
    .award { .block; .abs; .rb(24,24); .w(105); }
    .award-2 { .rb(147,24); .w(80); }
  }
  .hero + .hero { .h(400vh); .holder { overflow: visible; }}
  .seq { .c(#1e1e1e); .p(80,0);
    .holder { .h(auto); .tc; .flex-center; flex-direction: column; }
    p { .fs(20,1); }
    h3 { .fs(36,1); .mt(12); }
    a { .block; .mh-c; .p(12,60); .w(184); .-box; .fs(16,1); .br(3); .-a(#1e1e1e); .bgc(#fff); transition: background-color 0.4s, color 0.4s;
      @{hover} { .bgc(#1e1e1e); .c(#fff); }
    }
    video { .block; .rel; .w(194); .m(56, auto);}
    &.cartridge video { .w(178); }
    &.os video { .w(296); }
  }
  .map { .h(300vh);
    .img { .rel; }
    img { .block; .wh(auto, 100vh); object-fit: cover; object-position: bottom; }
    img + img { .abs; .lt; }
  }
  .reason { .h(200vh); .bgc(#EEEEEE);
    .bg { .abs; .lt; .f;
      video, img { .block; .f; object-fit: contain; object-position: center; }
    }
    p { .abs; .lt(50%,50%); .t-xyc; .wf; .tc; .fs(56,64); .bold; color: #1e1e1e;}
  }
  .feature { .bgc(#000);
    .holder { .h(auto); }
    li { .p(80,0); color: #fff;
      .txt {
        > p:first-of-type { .fs(20,1); .bold; }
        > p + p  { .mt(16); .fs(40,48); .bold; }
        > p + p + p  { .mt(40); .fs(20,32); .bold; .c(#C9C9C9); }
        .bottom { .mt(40);
          p { .medium; }
          p + p { .mt(16); .fs(64,1);
            img { .block; .h(72); }
          }
        }
      }
      video { .block; .mt(48); .wf; object-fit: contain; }
    }
  }
  .eco {
    .holder { .p(80,0,128); height: auto; }
    h3 { .fs(32,1); .tc; }
    .video { .mt(40);
      video { .block; .w(355); .mh-c; }
    }
    .smart { .mt(10);
      > p { .fs(12,1); .bold; .tc; }
      ul { .mt(16); .w(175); .mh-c; }
      li { .flex; justify-content: space-between; .fs(10,1);
        p:first-of-type { .rel; .pl(18); .bold;
          &:before { .cnt; .abs; .lt; .wh(10); .br(5); .bgc(#DFF4E3); }
        }
        p + p { .tr; .c(#666); }
      }
      li + li { .mt(10); }
      li:nth-child(2) p:first-of-type:before { .bgc(#BEE9C8); }
      li:nth-child(3) p:first-of-type:before { .bgc(#98E09B); }
      li:nth-child(4) p:first-of-type:before { .bgc(#7DD291); }
      li:nth-child(5) p:first-of-type:before { .bgc(#5DC775); }
      li:nth-child(6) p:first-of-type:before { .bgc(#4E85F6); }
    }
    .desc { .m(80, 36, 0); .fs(18,28); .bold; }
  }
}

@media (min-width: @screen-tp-min) {
  [platform] {
    .hero {
      .scene-1 {
        h2 img { .w(558); }
        h2 ~ p { .fs(80,96); }
      }
      .scene-2 {
        p { .fs(32,40); }
        p + p { .fs(80,96); }
      }
    }
    .seq {
      video { .w(280); .m(64, auto); }
      &.cartridge video { .w(258); }
      &.os video { .w(384); }
    }
    .reason { .bgc(#EBEAEB);
      p { .fs(80,96); }
    }
    .feature { .h(450vh);
      ul { .grid(3); }
      li { .rel; .w(100vw); .h(100vh); .-box; .inline-flex; flex-direction: column; .justify-center;
        .txt {
          p { .w(303); }
        }
        video { .abs; .rt(0, 50%); .t-yc; .wh(288); object-fit: cover; .m; }
      }
      li + li { .ml(100); }
    }
    .eco {
      .video video { .w(520); }
      .desc { .w(303); .mh-c; }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [platform] {
    h3 { .fs(40,1); .tc; }
    .hero {
      .scene-1 {
        h2 img { .w(744); }
        h2 ~ p { .fs(96,116); }
      }
      .scene-2 {
        p + p { .fs(96,116); }
      }
    }
    .seq { .h(928); .p;
      .holder { flex-direction: row; .hf;
        > div:first-child { .rel; .lt; width: auto; }
        > div { flex: 1; }
        p { .fs(24,1); }
        h3 { .fs(48,1); .mt(16); }
        a { .mt(48); }
      }
      video { .wh(auto,656); .m(0, auto); }
      &.cartridge video { .wh(auto,656); }
      &.os video { .w(384); }
      &.cartridge { .holder { flex-direction:row-reverse; } }
    }
    .map {
      .img { .hf; }
      video { .block; .f; object-fit: contain; object-position: bottom; .max-w(1600); .mh-c; }
    }
    .reason {
      p { .fs(96,1); }
    }
    .feature {
      li {
        .txt {
          p { .w(400); }
          > p:first-of-type { .fs(24,1); }
          > p + p  { .mt(24); .fs(56,72); }
          > p + p + p  { .mt(56); .fs(24,40); }
          .bottom { .mt(56);
            p { .fs(20,1); }
            p + p { .mt(20); .fs(80,1);
              img { .h(81); }
            }
          }
        }
        video { .wh(400); }
      }
    }
    .eco {
      .holder { .pb(144); }
      h3 { .fs(40,1); }
      .video {
        video { .wh(auto,348); .max-w(100%); }
      }
      .smart { .hide; }
      .desc { .m(40, auto, 0); .fs(24,40); .w(auto); .tc; }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [platform] {
    .hero {
      .scene-1 {
        h2 img { .w(806); }
        h2 ~ p { .fs(120,144); }
      }
      .scene-2 {
        p + p { .fs(120,1); }
      }
      .award { .rb(32,32); }
      .award-2 { .rb(147,32); }
    }
    .seq { .h(1000);
      video { .wh(auto,720); }
      &.cartridge video { .wh(auto,720); }
      &.os video { .w(444); }
    }
    .reason {
      p { .fs(120,1); }
    }
    .feature {
      li {
        video { .wh(560); }
      }
    }
    .structure {
      img { .w(1018); }
    }
    .custom {
      .viewer { .h(768);
        .control { .w(560);
          .slider {
            [slider] { .rel; }
            .swiper-navigation {
              .swiper-button-prev { .l(120); }
              .swiper-button-next { .r(120); }
            }
            img { .wh(80,240); .p(64,0,24); }
          }
        }
        .result { .w(521); }
      }
    }
    .spec {
      ul { .p(0,208); }
      li {
        &:first-child dt:first-child { .ml(-208); }
        &:last-child dt:first-child { .mr(-208); }
      }
    }
    .eco { .h(600vh);
      .holder { .h(100vh); .p; .flex-center; flex-direction: column; }
      .img { .rel; .wh(1280,434); .mh-c;
        img { .block; .mt(14); .abs; .lt(50%,50%); .t-xyc; .hf; .o(0); object-fit: contain; }
      }
      &[data-sq="-"] .sq-1, &[data-sq="0"] .sq-1 { .o(1); }
      &[data-sq="+"] .sq-221 { .o(1); }
      .img-loop (@i) when (@i > 0) {
        @prev: (@i - 1);
        @next: (@i + 1);
        &[data-sq="@{i}"] .sq-@{prev} { opacity: 1; z-index: 10; }
        &[data-sq="@{i}"] .sq-@{next} { opacity: 1; z-index: 10; }
        &[data-sq="@{i}"] .sq-@{i} { opacity: 1; z-index: 20; }
        .img-loop(@i - 1);
      }
      .img-loop (221);
      .video {
        video { .wh(auto,488); }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [platform] {
    .hero {
      .scene-1 {
        h2 img { .w(992); }
        h2 ~ p { .fs(128,1); }
      }
      .scene-2 {
        p + p { .fs(128,1); }
      }
    }
    .seq { .h(1088);
      video { .wh(auto,800); }
      &.cartridge video { .wh(auto,800); }
      &.os video { .w(492); }
    }
    .reason {
      p { .fs(128,1); }
    }
    .feature {
      li {
        video { .wh(640); }
      }
    }
    .structure {
      img { .w(1120); }
    }
    .custom {
      .viewer { .h(848);
        .control { .w(864); .pl(80); .-box;
          .slider {
            .swiper-navigation {
              a { .mt(-8); }
              .swiper-button-prev { .l(256); }
              .swiper-button-next { .r(256); }
            }
          }
          .slider + .slider { .mt(32);
            img { .p(112,0,24); }
            [slider] { .min-h(272); }
            [slider] + p { .mt(66); }
          }
        }
        .result { .w(575); }
      }
    }
    .spec {
      ul { .p(0,288); }
      li {
        &:first-child dt:first-child { .ml(-288); }
        &:last-child dt:first-child { .mr(-288); }
      }
    }
    .eco {
      .img { .wh(1440,448); }
    }
  }
}
</style>
